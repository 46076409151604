import request from '@/utils/request'


// 查询客户管理信息列表
export function listInfo(query) {
  return request({
    url: '/customer/info/list',
    method: 'get',
    params: query
  })
}

// 查询客户管理信息分页
export function pageInfo(query) {
  return request({
    url: '/customer/info/page',
    method: 'get',
    params: query
  })
}

// 查询客户管理信息详细
export function getInfo(data) {
  return request({
    url: '/customer/info/detail',
    method: 'get',
    params: data
  })
}

// 新增客户管理信息
export function addInfo(data) {
  return request({
    url: '/customer/info/add',
    method: 'post',
    data: data
  })
}

// 修改客户管理信息
export function updateInfo(data) {
  return request({
    url: '/customer/info/edit',
    method: 'post',
    data: data
  })
}

// 删除客户管理信息
export function delInfo(data) {
  return request({
    url: '/customer/info/delete',
    method: 'post',
    data: data
  })
}
