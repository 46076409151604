<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-form-model ref="form" :model="form" style="width: 50%;height: 100%">
        <a-form-model-item label="用户编号" prop="customerId">
          <a-input :readOnly="isReset" v-model="form.customerId" @keyup.enter="initCustomerInfo"/>
        </a-form-model-item>
        <a-form-model-item label="用户姓名" prop="customerName">
          <a-input :readOnly="isReset"  v-model="form.customerName" />
        </a-form-model-item>
        <a-form-model-item label="性别" prop="sex">
          <a-select :disabled="isReset" v-model="form.sex">
            <a-select-option :value="0">未知</a-select-option>
            <a-select-option :value="1">男</a-select-option>
            <a-select-option :value="2">女</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="年龄" prop="age">
          <a-input :readOnly="isReset" v-model="form.age"/>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobile">
          <a-input :readOnly="isReset" v-model="form.mobile"/>
        </a-form-model-item>
      </a-form-model>
      <a-table
        :loading="false"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="form.orderItemList"
        :pagination="false"
        :bordered="tableBordered"
        :scroll="{
          x: 400,
          y: 545
        }"
      >
      <span slot="status" slot-scope="record,text" >
<!--        0-未启动 1-已启动 2-已抓取结果 3-已上传-->
        <span v-if="record.status ==0">未启动</span>
        <span v-if="record.status ==1">已启动</span>
        <span v-if="record.status ==2">已抓取结果</span>
        <span v-if="record.status ==3">已上传</span>
      </span>

        <span slot="operation" slot-scope="text, record">
          <a-divider v-if="record.status ==0" type="vertical" v-hasPermi="['order:item:edit']" />
          <a v-if="record.status ==0" @click="createSocket(record)" v-hasPermi="['game:info:edit']">
            <a-icon type="edit" />启动
          </a>
          <a-divider v-if="record.status ==1" type="vertical" v-hasPermi="['order:item:edit']" />
          <a @click="grabResult(record.id)" v-if="record.status ==1" v-hasPermi="['order:item:edit']">
            <a-icon type="edit" />抓取结果
          </a>
           <a-divider v-if="record.status ==2" type="vertical" v-hasPermi="['order:item:edit']" />
<!--          <a @click="uploadResult(record.id)" v-if="record.status ==2" v-hasPermi="['order:item:edit']">-->
<!--            <a-icon type="edit" />上传-->
<!--          </a>-->
        </span>
      </a-table>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="cancel">
            重置
          </a-button>
        </a-space>
      </div>
      <upload
        ref="upload"
        @ok="handleOk"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { orderInfo, grabResult,startGame } from '@/api/order/order'
import { tableMixin } from '@/store/table-mixin'
import {listGame} from "@/api/game/info";
import SellInfo from "./modules/sellInfo"
import {getInfo} from "@/api/customer/info";
import {getConfigKey} from "@/api/system/config";
import Upload from "./modules/upload"
export default {
  name: 'Confirm',
  components: {SellInfo,Upload},
  mixins: [tableMixin],
  data () {
    return {
      isReset:false,
      id:null,
      socket: null,
      form:{
        customerId: null,
        customerName: null,
        sex: null,
        age: null,
        mobile: null,
        orderItemList:[],
      },
      columns:[
        {
          title: '游戏名称',
          dataIndex: 'gameName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '游戏状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender(f){
            if(f==0){
              return '未启动'
            }else if(f==1){
              return '已启动'
            }else if(f==2){
              return '已抓取结果'
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        },
      ]
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  methods: {
    initCustomerInfo(){
      if(this.form.customerId){
        console.log("url",this.form.customerId)
        let url = this.form.customerId+'';
        let id = url.split("=")[1]
        this.getOrderInfo(id)
      }
    },
    getOrderInfo(customerId){
      orderInfo(customerId).then(res=>{
        this.isReset=true
        this.reset()
        this.form = res.data
        console.log("用户订单信息",this.form)
      })
    },

    createSocket(record) {
      const socket = new WebSocket('ws://'+record.socketUrl);

      // 监听连接打开事件
      socket.addEventListener('open', () => {
        console.log('WebSocket连接已打开！');
        // 在连接打开后发送消息到服务端
        this.startGame(record,socket);
      });

      // 监听消息接收事件
      socket.addEventListener('message', (event) => {
        console.log('收到服务端的消息：', event.data);
        startGame(record.id).then(res=>{
          this.$message.success("启动成功")
          this.getOrderInfo(this.form.customerId)
        })
      });

      // 监听连接关闭事件
      socket.addEventListener('close', () => {
        console.log('WebSocket连接已关闭！');
        socket.close()
      });

      // 返回Socket对象，以便在其他方法中继续使用
      return socket;
    },

    startGame(record,socket){
      // 连接已打开，发送请求到服务端
      socket.send(this.form.customerId+","+record.gameName);
    },

    grabResult(id){
      this.id=id
      this.$refs.upload.importExcelHandleOpen()
    },

    handleOk(){
      grabResult(this.id).then(res=>{
        this.getOrderInfo(this.form.customerId)
      })
    },

    cancel(){
      this.reset()
      this.isReset = false;
    },
    reset(){
      this.form={
          customerId: null,
          customerName: null,
          sex: null,
          age: null,
          mobile: null,
          orderItemList: []
      }
    },
  }
}
</script>
